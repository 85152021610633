import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import './ButtonBack.styl';
import withLocation from '../../../providers/withLocation';

const ButtonBack = ({ backButtonText, isLink, link }) => {
    const renderContent = () => (
        <>
            {backButtonText !== null ? (
                <div>
                    <span className='txt-red'>RETOUR</span> {backButtonText}
                </div>
            ) : (
                <div>RETOUR</div>
            )}
            <div className='icon icon-back' />
        </>
    );

    return isLink ? (
        <Link to={link}>
            <div className='back-button'>{renderContent()}</div>
        </Link>
    ) : (
        <button
            className='back-button'
            type='button'
            onClick={() => navigate(-1, { replace: true })}
        >
            {renderContent()}
        </button>
    );
};
ButtonBack.defaultProps = {
    backButtonText: null,
    isLink: false,
    link: null,
};

ButtonBack.propTypes = {
    backButtonText: PropTypes.string,
    isLink: PropTypes.bool,
    link: PropTypes.string,
};

export default withLocation(ButtonBack);
