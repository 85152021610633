/* eslint-disable no-underscore-dangle */
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import screenfull from 'screenfull';

import './Header.styl';
import logo from '../../../contents/images/app/LOGO-BB.svg';
import HomeButton from '../../components/HomeButton';
import ButtonBack from '../../components/ButtonBack/index';

// const logo = require("../../../assets/img/logo_white.svg");

const Header = ({ backButton, backButtonText, headerTitle, homeButton }) => {
    const [isFs, setIsFs] = useState(false);

    const fullscreen = () => {
        if (typeof window !== 'undefined' && screenfull.isEnabled) {
            const getFullScreenNode = () =>
                document.documentElement || document.body;
            screenfull.toggle(getFullScreenNode());
        }
    };

    if (typeof window !== 'undefined' && screenfull.isEnabled) {
        screenfull.on('change', () => {
            setIsFs(screenfull.isFullscreen);
        });
    }
    return (
        <header className='container'>
            <div id='app-version'>v {process.env.__VERSION__}</div>
            <div>
                <Link to='/'>
                    <div className='logo'>
                        <img src={logo} alt='' />
                    </div>
                </Link>
            </div>
            {isFs ? null : (
                <button
                    className='fullscreen'
                    onClick={fullscreen}
                    type='button'
                >
                    <i className='icon icon-fullscreen' />
                </button>
            )}

            {headerTitle && <div className='header-title'>{headerTitle}</div>}

            <div className='header-buttons'>
                {homeButton && <HomeButton />}
                {backButton && <ButtonBack backButtonText={backButtonText} />}
            </div>
        </header>
    );
};

Header.defaultProps = {
    backButton: false,
    backButtonText: null,
    headerTitle: null,
    homeButton: false,
};

Header.propTypes = {
    backButton: PropTypes.bool,
    backButtonText: PropTypes.string,
    headerTitle: PropTypes.string,
    homeButton: PropTypes.bool,
};

export default Header;
