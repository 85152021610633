/* eslint-disable no-param-reassign */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import './InputBloc.styl';

const InputBloc = ({ errors, label, name, register, rules, ...props }) => (
    <div
        className={clsx('input-bloc', {
            inputError: errors[name],
        })}
    >
        <label htmlFor={name} className='h3-like'>
            {label}
        </label>

        <input name={name} {...props} {...register(name, rules)} />

        {errors[name] && (
            <span className='error-msg'>{errors[name].message}</span>
        )}
    </div>
);

InputBloc.defaultProps = {
    rules: {},
    type: 'string',
};

InputBloc.propTypes = {
    errors: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    rules: PropTypes.object,
    type: PropTypes.string,
};

export default InputBloc;
