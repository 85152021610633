import PropTypes from 'prop-types';
import React from 'react';
import { Grommet } from 'grommet';

import Header from './Header/Header';
import IdleLayout from './IdleLayout';
import grommetConfig from '../../../config/grommet';
import FormLogin from '../components/FormLogin';
import { isLoggedIn } from '../../services/auth';

const MainLayout = ({ children, headerOptions }) => {
    const isBrowser = () => typeof window !== 'undefined';

    if (isBrowser()) {
        if (!isLoggedIn()) {
            return <FormLogin />;
        }
    }
    return (
        <Grommet plain theme={grommetConfig}>
            <IdleLayout>
                <div id='app'>
                    <div id='content-wrap'>
                        <Header {...headerOptions} />

                        <main>
                            <div id='content'>{children}</div>
                        </main>
                    </div>
                </div>
            </IdleLayout>
        </Grommet>
    );
};

MainLayout.defaultProps = {
    headerOptions: {},
};

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
    headerOptions: PropTypes.shape({
        backButton: PropTypes.bool,
        backButtonText: PropTypes.string,
        headerTitle: PropTypes.string,
        homeButton: PropTypes.bool,
    }),
};

export default MainLayout;
