const rules = { required: 'Ce champ est requis' };

export default {
    email: {
        label: 'Adresse Mail',
        name: 'email',
        placeholder: 'ex : jeandupont@mail.com',
        rules: {
            ...rules,
            pattern: {
                message: "Le format du mail n'est pas valide",
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            },
        },
        type: 'email',
    },
    id: {
        label: 'Identifiant du magasin',
        name: 'id',
        placeholder: 'ex : 82443934',
        required: true,
        rules,
    },
    password: {
        label: 'Mot de passe',
        name: 'password',
        placeholder: 'ex : M0nM0t2P@sse',
        rules,
        type: 'password',
    },
};
