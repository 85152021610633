/* eslint-disable no-underscore-dangle */
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import fields from './config';
import { handleLogin } from '../../../services/auth';
import useFirebase from '../../../services/firebase/useFirebase';
import InputBloc from '../InputBloc';
import { formatDateDDMMYYYY } from '../../../utils/dates';

function FormLogin() {
    // eslint-disable-next-line no-underscore-dangle
    const [formError, setFormError] = useState(null);
    const [storesList, setStoresList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(
                'https://raw.githubusercontent.com/JimmyBeldone/brico-shared/main/listWithLabel.json',
            );
            setStoresList(res?.data || []);
        };

        fetchData();
    }, []);
    const { id } = fields;

    const firebase = useFirebase();
    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm();

    const onSubmit = (data) => {
        if (!firebase) return;

        if (storesList.length === 0) {
            setFormError(
                'Une erreur est survenue sur le serveur, veuillez réessayer',
            );
            return;
        }

        const storeExists = storesList.find(
            (store) => store.id === parseInt(data.id, 10),
        );

        const email = 'store.brico@brico.com';
        const password = '$t0r3.Br!c0';

        if (storeExists) {
            firebase
                .auth()
                // .signInWithEmailAndPassword(data.email, data.password)
                .signInWithEmailAndPassword(email, password)
                .then((resp) => {
                    handleLogin(
                        resp.user,
                        storeExists.id,
                        storeExists.label,
                    ).then(() => {
                        const storeRef = firebase
                            .database()
                            .ref(`/stores/${data.id}`);

                        storeRef.get().then((snapshot) => {
                            if (!snapshot.exists()) {
                                storeRef.set({
                                    appVersion: process.env.__VERSION__,
                                    id: storeExists.id,
                                    label: storeExists.label,
                                    lastTimeActive: formatDateDDMMYYYY(
                                        new Date(),
                                    ),
                                });
                                navigate('/?logged');
                            } else {
                                storeRef.transaction((item) => {
                                    if (item) {
                                        return {
                                            ...item,
                                            appVersion: process.env.__VERSION__,
                                            lastTimeActive: formatDateDDMMYYYY(
                                                new Date(),
                                            ),
                                        };
                                    }
                                    navigate('/?logged');
                                    return item;
                                });
                            }
                        });
                    });
                })
                .catch((error) => {
                    console.log('onSubmit -> error', error);
                    setFormError(
                        'Une erreur est survenue sur le serveur, veuillez réessayer plus tard',
                    );
                });
        } else {
            setFormError(
                'Votre identifiant est incorrecte, utilisez le code "legacy"',
            );
        }
    };

    return (
        <form
            name='FormLogin'
            onSubmit={handleSubmit(onSubmit)}
            style={{ margin: '10% auto', maxWidth: '500px' }}
        >
            {/* {[id, email, password].map((item) => ( */}
            {[id].map((item) => (
                <InputBloc
                    key={`form-${item.name}-field-${item.name}`}
                    {...item}
                    register={register}
                    errors={errors}
                />
            ))}
            <div
                style={{
                    color: '#E63228',
                    fontSize: '20px',
                    margin: '15px auto',
                    textAlign: 'center',
                }}
            >
                {formError && formError}
            </div>
            <input type='submit' className='button-text' value='Valider' />
        </form>
    );
}

FormLogin.propTypes = {};

export default FormLogin;
