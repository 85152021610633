const activeEnv = process.env.MODE || process.env.NODE_ENV || `development`;

require(`dotenv`).config({
    path: `.env.${activeEnv}`,
});

module.exports = {
    backgroundColor: `#171F2C`,

    description: `Brico Dépot - Menuiserie`,

    icon: `src/contents/images/app/logo-512.png`,

    iconName: 'logo-512',

    keywords: [],

    // manifest config
    langs: {
        all: ['fr'],
        default: {
            description: `Brico Dépot - Menuiserie`,
            lang: `fr`,
            name: `Brico Dépot - Menuiserie`,
            short_name: `Brico Dépot - Menuiserie`,
        },
        others: [],
    },

    // SEO
    name: `Brico Dépot - Menuiserie`,

    pathPrefix: `/`,

    siteUrl:
        process.env.NODE_ENV === 'production'
            ? 'https://brico-app-menuiserie.com'
            : `http://localhost:8000`,
    // siteUrl: process.env.GATSBY_SITE_URL || `http://localhost:8000`,

    themeColor: `#171F2C`,

    title: `Brico Dépot - Menuiserie`,
    titleAlt: `Brico Dépot - Menuiserie`,
};
