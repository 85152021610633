import { Location } from '@reach/router';
import React from 'react';

const withLocation = (ComponentToWrap) => (props) =>
    (
        <Location>
            {({ location, navigate }) => (
                <ComponentToWrap
                    {...props}
                    location={location}
                    navigate={navigate}
                />
            )}
        </Location>
    );

export default withLocation;
