import { Link } from 'gatsby';
import React from 'react';

import './HomeButton.styl';

const HomeButton = () => (
    <Link to='/'>
        <div className='home-button'>
            <div className='icon icon-home' />
            <div>Accueil</div>
        </div>
    </Link>
);

export default HomeButton;
