// eslint-disable-next-line import/prefer-default-export
export function formatDateDDMMYYYY(date) {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    const hh = date.getHours();
    const min = date.getMinutes();

    if (dd < 10) {
        dd = `0${dd}`;
    }
    if (mm < 10) {
        mm = `0${mm}`;
    }

    return `${dd}/${mm}/${yyyy} - ${hh}h${min}`;
}
