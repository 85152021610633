/* eslint-disable no-use-before-define */
// import { navigate } from 'gatsby';
// import { Layer } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';
// import { useIdleTimer } from 'react-idle-timer';

// import { PAGE_HOME } from '../../constants/router';
// import Screensaver from '../components/Screensaver';

function IdleLayout({ children }) {
    // const [idle, setIdle] = useState(false);

    // const handleOnIdle = () => {
    //     // setIdle(true);
    //     console.log('last active', getLastActiveTime());
    // };

    // const handleOnActive = () => {
    //     setIdle(false);
    //     navigate(PAGE_HOME);
    //     console.log('time remaining', getRemainingTime());
    // };

    // const { getLastActiveTime, getRemainingTime } = useIdleTimer({
    //     debounce: 500,
    //     onActive: handleOnActive,
    //     onIdle: handleOnIdle,
    //     // timeout: 5000,
    //     timeout: 2 * 60 * 1000,
    // });

    return (
        <div className='idle-layout'>
            {children}
            {/* {idle ? (
                <Layer onEsc={handleOnActive} animate full animation='fadeIn'>
                    <Screensaver />
                </Layer>
            ) : null} */}
        </div>
    );
}

IdleLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default IdleLayout;
